import styled from 'styled-components';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@assets/icons/styled/StyledIcons';
import { CATALOG_COVER_INFO_WIDTH } from '../constants';
import { CAROUSEL_WRAPPER_MARGIN } from './constants';

export const CarouselWrapper = styled.div`
  position: relative;
  padding-top: 30px;
  width: calc(100% - ${CATALOG_COVER_INFO_WIDTH + CAROUSEL_WRAPPER_MARGIN}px);
  border-radius: 8px;
  cursor: pointer;
  margin-left: ${CAROUSEL_WRAPPER_MARGIN}px;
  ${({ theme }) => theme.BreakpointTablet`
        margin-left: initial;
   `};
  ${({ theme }) => theme.BreakpointMobile`
        width: 270px;
        height: auto;
   `};
`;

export const StyledShadow = styled.img`
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
`;

export const CarouselActiveImage = styled.div<{ backgroundImageUrl?: string }>`
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl &&
    `
      background-image: url('${backgroundImageUrl}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
  `}
  ${({ theme }) => theme.BreakpointMobile`
        width: 270px;
        height: 189px;
   `};
`;

export const Navbar = styled.div`
  position: absolute;
  top: 38px;
  background: #fff;
  height: 41px;
  width: 330px;
  display: flex;
  align-items: center;
  box-shadow: 0 8px 18px -6px rgb(24 39 75 / 12%),
    0 12px 42px -4px rgb(24 39 75 / 12%);
  border-radius: 50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: initial;
`;

export const NavbarText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 234px;
  font-family: ${({ theme }) => theme.fonts.Walsheim.regular};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.purple.mulledWine};
  height: 100%;
  cursor: initial;
  text-align: center;
  padding: 13px 0;
`;

export const IconWrapper = styled.div<{ alignRight?: boolean }>`
  width: 48px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  ${({ alignRight }) =>
    alignRight
      ? `
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    `
      : `
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    `}

  ${ArrowLeftIcon}, ${ArrowRightIcon} {
    path {
      fill: ${({ theme }) => theme.colors.purple.mulledWine};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray.wildSand};
  }
`;
