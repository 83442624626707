import React from 'react';
import { TemplateFragment } from 'graphql-types';
import { ViewIcon } from '@assets/icons/styled/StyledIcons';
import {
  PreviewWrapper,
  HoverLayout,
  TemplateInfo,
  IconWrapper,
  PreviewButton,
  ButtonText,
  TemplateTitle,
  PreviewImage,
} from './TemplatePreviewStyled';

interface ITemplatePreviewProps {
  template: TemplateFragment;
  selectedCategoryId?: string;
}

const TemplatePreview: React.FC<ITemplatePreviewProps> = ({
  template,
  selectedCategoryId,
}) => {
  const slug = template.slug?.localized;
  const catalogSlug = template.catalog?.slug?.localized;

  let iconBackgroundColor = template.category[0].iconBackgroundColor.hex;
  let iconImgSrc = template.category[0].icon.asset.url;

  if (selectedCategoryId) {
    template.category.forEach((c) => {
      if (c._id === selectedCategoryId) {
        iconBackgroundColor = c.iconBackgroundColor.hex;
        iconImgSrc = c.icon.asset.url;
      }
    });
  }

  return (
    <PreviewWrapper
      onClick={() => {
        window.location.href = `/${catalogSlug}/${slug}`;
      }}
    >
      <HoverLayout>
        <PreviewButton>
          <ViewIcon width={18} height={18} />
          <ButtonText>VIEW DETAILS</ButtonText>
        </PreviewButton>
      </HoverLayout>
      <PreviewImage
        backgroundColor={template?.coverImageBgColor?.hex}
        backgroundImageUrl={template?.coverImage?.asset?.url ?? ''}
      />
      <TemplateInfo>
        <IconWrapper backgroundColor={iconBackgroundColor}>
          <img src={iconImgSrc} alt="" />
        </IconWrapper>
        <TemplateTitle>{template?.title?.localized}</TemplateTitle>
      </TemplateInfo>
    </PreviewWrapper>
  );
};

export default TemplatePreview;
