import React from 'react';
import {
  CatalogCategoryFragment,
  CatalogGroupFragment,
  CatalogQuery,
  TemplateFragment,
} from 'graphql-types';
import { graphql, Link } from 'gatsby';
import { useHeaderContext } from '@components/Layout/Header/headerContext';
import {
  CatalogCover,
  CatalogDescription,
  CatalogTitle,
  CatalogWrapper,
  CoverContent,
  CatalogInfo,
  Categories,
  Category,
  GroupsWrapper,
  CategoriesWrapper,
} from './CatalogStyled';
import CatalogCarousel from './CatalogCarousel/CatalogCarousel';
import CatalogGroup from './CatalogGroup/CatalogGroup';

interface ICatalogProps {
  title: string;
  description: string;
  coverImage: CatalogQuery['catalogConfig']['coverImage'];
  categories: CatalogCategoryFragment[];
  highlightedTemplates: TemplateFragment[];
  groups: CatalogGroupFragment[];
  selectedCategoryId: string;
  catalogInstanceSlug: string;
  categoryFiltered: TemplateFragment[];
}

const Catalog: React.FC<ICatalogProps> = ({
  title,
  description,
  coverImage,
  categories,
  highlightedTemplates,
  groups,
  selectedCategoryId,
  catalogInstanceSlug,
  categoryFiltered,
}) => {
  const {
    state: { announcementBarVisible },
  } = useHeaderContext();

  return (
    <CatalogWrapper announcementBarVisible={announcementBarVisible}>
      <CatalogCover backgroundImageUrl={coverImage?.asset?.url}>
        <CoverContent>
          <CatalogInfo>
            <CatalogTitle>{title}</CatalogTitle>
            <CatalogDescription>{description}</CatalogDescription>
          </CatalogInfo>
          <CatalogCarousel highlightedTemplates={highlightedTemplates} />
        </CoverContent>
      </CatalogCover>
      <CategoriesWrapper>
        <Categories>
          <Link to={`/${catalogInstanceSlug}`} replace>
            <Category active={!selectedCategoryId}>All templates</Category>
          </Link>
          {categories.map(({ _id, categoryName, slug }) => (
            <Link
              key={_id}
              to={`/${catalogInstanceSlug}/${slug.localized}`}
              replace
            >
              <Category active={selectedCategoryId === _id}>
                {categoryName.localized ?? ''}
              </Category>
            </Link>
          ))}
        </Categories>
      </CategoriesWrapper>
      {selectedCategoryId ? (
        <GroupsWrapper>
          <CatalogGroup
            categoryFiltered={categoryFiltered}
            selectedCategoryId={selectedCategoryId}
          />
        </GroupsWrapper>
      ) : (
        groups.length > 0 && (
          <GroupsWrapper>
            {groups.map((group) => (
              <CatalogGroup
                key={group._id}
                group={group}
                selectedCategoryId={selectedCategoryId}
              />
            ))}
          </GroupsWrapper>
        )
      )}
    </CatalogWrapper>
  );
};

export const query = graphql`
  fragment CatalogCategory on SanityCatalogCategory {
    _id
    _key
    slug {
      localized(locale: $locale)
    }
    categoryName {
      localized(locale: $locale)
    }
    iconBackgroundColor {
      hex
    }
    icon {
      ...GatsbyImageWithUrl
    }
  }
  fragment Template on SanityNewTemplate {
    _id
    _key
    catalog {
      slug {
        localized(locale: $locale)
      }
    }
    title {
      localized(locale: $locale)
    }
    slug {
      localized(locale: $locale)
    }
    coverImageBgColor {
      hex
    }
    highlighted
    coverImage {
      ...GatsbyImageWithUrl
    }
    category {
      ...CatalogCategory
    }
  }

  fragment CatalogGroup on SanityCatalogGroup {
    _id
    _key
    title {
      localized(locale: $locale)
    }
    templates {
      ...Template
    }
  }
`;

export default Catalog;
