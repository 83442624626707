import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import Catalog from '@components/Catalog/Catalog';
import track from '@src/tracking/track';
import useSaveUtmParamsToLocalStorage from '@src/hooks/useSaveUtmParamsToLocalStorage';
import { ICatalogPageContextProps } from '@contexts/interfaces';
import { CatalogQuery } from '../../graphql-types';
import PageWrapper from './shared/PageWrapper';

const TRACKING_IDENTIFIER = 'catalog';

const CatalogOverview = ({
  data,
  pageContext,
}: PageProps<CatalogQuery, ICatalogPageContextProps>) => {
  const config = data?.catalogConfig;
  const title = config?.title?.localized || '';
  const description = config?.description?.localized || '';
  const coverImage = config?.coverImage ?? null;
  const categories = data?.catalogCategory.nodes ?? [];
  const groups = data?.catalogGroup.nodes ?? [];
  const { selectedCategoryId } = pageContext;

  let categoryFiltered: CatalogQuery['newTemplate']['nodes'] = [];

  const allTemplates = data?.newTemplate?.nodes;

  if (allTemplates && selectedCategoryId) {
    categoryFiltered = allTemplates.filter((template) =>
      template.category.some((c) => c._id === selectedCategoryId)
    );
  }

  const highlightedTemplates =
    data?.newTemplate.nodes.filter((node) => node.highlighted === true) ?? [];
  useSaveUtmParamsToLocalStorage();
  useEffect(() => {
    track({
      page: {
        categorySlug: 'catalog',
        pageSlug: config?.slug?.localized,
      },
    });
  }, [config?.slug?.localized]);

  return (
    // TODO: Set up blog meta data & navigation bar in Sanity
    <PageWrapper
      locale={pageContext.locale}
      identifier={TRACKING_IDENTIFIER}
      navigationBar={config?.navigationBar}
      metadata={config?.metadata}
      announcementId={data?.catalogConfig?.navigationBar?.announcement?._id}
      footerBar={data?.sanityFooterBar}
    >
      <Catalog
        title={title}
        description={description}
        coverImage={coverImage}
        categories={categories}
        highlightedTemplates={highlightedTemplates}
        groups={groups}
        categoryFiltered={categoryFiltered}
        selectedCategoryId={selectedCategoryId}
        catalogInstanceSlug={config.slug.localized}
      />
    </PageWrapper>
  );
};

export const query = graphql`
  query Catalog($id: String!, $locale: String!, $footerBarId: String!) {
    sanityFooterBar(_id: { eq: $footerBarId }) {
      ...FooterBar
    }
    catalogConfig: sanityCatalogConfig(_id: { eq: $id }) {
      title {
        localized(locale: $locale)
      }
      description {
        localized(locale: $locale)
      }
      slug {
        localized(locale: $locale)
      }
      metadata {
        ...SEO
      }
      navigationBar {
        ...Header
      }
      coverImage {
        ...ImageUrl
      }
      redirects
    }
    catalogGroup: allSanityCatalogGroup(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...CatalogGroup
      }
    }
    catalogCategory: allSanityCatalogCategory(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...CatalogCategory
      }
    }
    newTemplate: allSanityNewTemplate(
      filter: { catalog: { _id: { eq: $id } } }
    ) {
      nodes {
        ...Template
      }
    }
  }
`;

// ts-prune-ignore-next
export default CatalogOverview;
